<template>
  <div>
    <Form ref='search' :model="searchData" :rules="ruleInline" inline>
    <Row>
      <Col span="3">
        <FormItem label="设备ID" prop="machine_id" label-position="left" :label-width="80">
          <Input v-model.trim="searchData.machine_id" laceholder="设备ID" @on-search="getMachines" />
        </FormItem>
      </Col>
      <Col span="3">
        <FormItem label="业务名" prop="business" label-position="left" :label-width="80">
          <Input v-model.trim="searchData.business" laceholder="业务名" @on-search="getMachines" />
        </FormItem>
      </Col>
      <Col span="2">
        <FormItem label="状态" prop="enabled" label-position="left" :label-width="80">
          <Select v-model="searchData.enabled">
            <Option :value="-1">全部</Option>
            <Option :value="1">开启</Option>
            <Option :value="0">关闭</Option>
          </Select>
        </FormItem>
      </Col>
      <Col span="5">
        <FormItem prop="enabled" label-position="left" :label-width="80">
          <Button type="primary" @click="getMachines" style="margin-right: 6px">查 询</Button>
          <Button type="primary" @click="resetSearchForm">重 置</Button>
        </FormItem>
      </Col>
    </Row>
    </Form>

    <Card :shadow="true" style="text-align: left">
      <Button type="primary" ghost @click="createMachine" style="margin-right: 16px"><Icon type="ios-add" />添加设备</Button>
      <Button type="primary" ghost :loading="loading" @click="dataExport">
        <span v-if="!loading"><Icon type="ios-download-outline" />导出数据</span>
        <span v-else>正在导出</span>
      </Button>
      <Button type="error" ghost @click="deleteMachine" v-if="isShowDelBtn" style="margin-right: 16px"><Icon type="ios-trash-outline" />删除设备</Button>
    </Card>
    <Row>
      <Col span="24">
        <Table :loading="loading" border ref="table" :columns="columns" :data="machineData" @on-selection-change="selecte" @on-select-all="selectAll" @on-select-all-cancel="selectAllCancel">
          <template slot-scope="{ row }" slot="cron_start">
            <div v-for="(cron, index) in row.crontab" :key="index" style="text-align: left">{{cron.start}}</div>
          </template>
          <template slot-scope="{ row }" slot="cron_stop">
            <div v-for="(cron, index) in row.crontab" :key="index" style="text-align: left">{{cron.stop}}</div>
          </template>
          <template slot-scope="{ row }" slot="created_at">
            <div style="text-align: left">{{$moment(row.created_at).format('YYYY-MM-DD HH:mm:ss')}}</div>
          </template>
          <template slot-scope="{ row }" slot="updated_at">
            <div style="text-align: left">{{$moment(row.updated_at).format('YYYY-MM-DD HH:mm:ss')}}</div>
          </template>
        </Table>
      </Col>
    </Row>
    <Row style="margin-top: 16px">
      <Col span="24">
        <Page show-sizer show-total :total="searchData.totalCount" @on-change="pageOnChange" @on-page-size-change="pageOnSizeChange"/>
      </Col>
    </Row>
    <base-drawer :is-show="drawer.isShow" :title="drawer.title" @closeDrawer="closeDrawer" @submit="submit">
      <Form ref='machineForm' :model="formData" :rules="ruleInline">
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="设备ID(以换行符分割)" prop="machine_id" label-position="top">
              <Input v-model.trim="formData.machine_id" type="textarea" :rows="1" placeholder="设备ID,以换行符分割" />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="下行/上行比例(%) 等于0:不判断,大于0:判断" prop="recv_send_rate" label-position="top">
              <Input type='text' v-model.number="formData.recv_send_rate" placeholder="下行占下行比例" />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="最大上行带宽(Mb)" prop="max_upload" label-position="top">
              <Input type='text' v-model.number="formData.max_upload" placeholder="最大上行带宽" />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="最大下行带宽(Mb)" prop="max_download" label-position="top">
              <Input type='text' v-model.number="formData.max_download" placeholder="最大下行带宽" />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
        <Col span="12">
            <FormItem label="下行带宽比例(%)" prop="pull_ratio" label-position="top">
              <Input type='number' min="1" max="100" @input="checkValue" v-model.number="formData.pull_ratio" placeholder="下行带宽比例" />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="状态" prop="enabled" label-position="top">
              <i-switch size="large" v-model="formData.enabled" :true-value="1" :false-value="0" true-color="#19be6b" false-color="#ed4014">
                <span slot="open">开启</span>
                <span slot="close">暂停</span>
              </i-switch>
            </FormItem>
          </Col>
        </Row>
        <Divider plain orientation="left">定时任务</Divider>
        <Button type="primary" long @click="appendCron" ghost>添加时间段</Button>
        <Row :gutter="32" v-for="(task, index) in formData.crontab" :key="index">
          <Col span="10">
            <FormItem label="开始时间" prop="start" label-position="top">
              <Select v-model.trim="formData.crontab[index].start">
                  <Option v-for="hour of 24" :value="`0 ${hour-1} * * *`" :key="hour-1">每天{{ hour-1 }}点</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="10">
            <FormItem label="结束时间" prop="end" label-position="top">
              <Select v-model.trim="formData.crontab[index].stop">
                <template v-for="hour of 24">
                    <Option  v-if="hour-1 > formData.crontab[index].start.split(' ')[1]" :value="`0 ${hour-1} * * *`" :key="hour-1">每天{{ hour-1 }}点</Option>
                </template>
              </Select>
            </FormItem>
          </Col>
          <Col  span="4">
            <FormItem label="操作" prop="end" label-position="top">
              <Button type="error" @click="removeCron(index)" ghost>删 除</Button>
            </FormItem>
          </Col>
        </Row>
        <Divider plain orientation="left">过滤信息</Divider>
        <Row :gutter="32">
          <Col span="24">
            <FormItem label="排除不需要监控IP (每行一个IP或匹配起始IP)" prop="enabled" label-position="top">
              <Input v-model="formData.filter" type="textarea" :rows="5" placeholder="每行一个IP" />
            </FormItem>
          </Col>
        </Row>
        <Divider plain orientation="left">备注</Divider>
        <Row :gutter="32">
          <Col span="24">
            <Input v-model="formData.comment" type="textarea" :autosize="{minRows: 2,maxRows: 5}" placeholder=""></Input>
          </Col>
        </Row>
      </Form>
    </base-drawer>
  </div>
</template>

<script>
import {del, get, post, put} from "@/api/http";
import BaseDrawer from "@/components/base/baseDrawer";
import Notice from "@/components/baseNotice";
import expandRow from './IndexTableExpand.vue';
import moment from "moment";
import VXETable from 'vxe-table'
import XEUtils from 'vxe-table'
import axios from "axios";

// 创建一个简单的超链接渲染
VXETable.renderer.add('Enabled', {
  // 默认显示模板
  renderDefault (h, renderOpts, params) {
    let { row } = params
    if (row.enabled === 1) {
      return [
        <span style="color: green">开启</span>
      ]
    } else {
      return [
        <span style="color: red">停用</span>
      ]
    }
  },
})

export default {
  name: "Bwstress",
  components: {
    BaseDrawer,
  },
  data() {
    return {
      loading: false,
      isEdit: false,
      drawer: {
        isShow: false,
        title: ''
      },
      searchData: {
        machine_id: '',
        enabled: -1,
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
      formData: {
        machine_id: null,
        max_download: 0,
        max_upload: 0,
        recv_send_rate: 0,
        pull_ratio: 50,
        enabled: 0,
        crontab: [{start: "0 1 * * *", stop: "0 9 * * *"}],
        filter: "0.|127.|172.|192."
      },
      ruleInline: {
        // machine_id: [
        //   { required: true, message: '请填写机器ID', trigger: 'blur' }
        // ],
        // max_download: [
        //   { required: true, message: '请填写最大下行带宽', trigger: 'blur' },
        //   { type: 'integer', message: '非整数值', trigger: 'blur', transform:(value) => Number(value) }
        // ],
        // max_upload: [
        //   { required: true, message: '请填写最大上行带宽', trigger: 'blur' },
        //   { type: 'integer', message: '非整数值', trigger: 'blur', transform:(value) => Number(value) }
        // ],
        // rs_userate: [
        //   { required: true, message: '请填写上下行比例', trigger: 'blur' },
        //   { type: 'integer', message: '非整数值', trigger: 'blur', transform:(value) => Number(value) }
        // ]
      },
      columns: [
        // {
        //   type: 'expand',
        //   width: 50,
        //   render: (h, params) => {
        //     return h(expandRow, {
        //       props: {
        //         row: params.row
        //       }
        //     })
        //   }
        // },
        {
          type: 'index',
          width: 70,
          align: 'left'
        },
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '设备ID',
          key: 'machine_id'
        },
        {
          title: '业务名',
          key: 'business'
        },
        {
          title: '最大下行(Mbps)',
          key: 'max_download'
        },
        {
          title: '最大上行(Mbps)',
          key: 'max_upload'
        },
        {
          title: '下行/上行比率(%)',
          key: 'recv_send_rate'
        },
        {
          title: '下行带宽比例(%)',
          key: 'pull_ratio'
        },
        {
          title: '开始执行',
          key: 'cron_start',
          slot: 'cron_start',
          width: 100,
          align: 'left',
        },
        {
          title: '停止执行',
          key: 'cron_stop',
          slot: 'cron_stop',
          width: 100,
          align: 'left',
        },
        {
          title: '创建时间',
          key: 'created_at',
          width: 120,
          sortable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return moment(a).valueOf() > moment(b).valueOf() ? -1 : 1
            } else {
              return moment(a).valueOf() < moment(b).valueOf() ? -1 : 1
            }
          },
          render: (h, {row}) => {
            return h('span', moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'))
          }
        },
        {
          title: '更新时间',
          key: 'updated_at',
          width: 120,
          sortable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return moment(a).valueOf() > moment(b).valueOf() ? -1 : 1
            } else {
              return moment(a).valueOf() < moment(b).valueOf() ? -1 : 1
            }
          },
          render: (h, {row}) => {
            return h('span', moment(row.updated_at).format('YYYY-MM-DD HH:mm:ss'))
          }
        },
        {
          title: '状 态',
          key: 'enabled',
          width: 88,
          sortable: true,
          render: (h, {row}) => {
            let statusColor = {
              "0": ['暂停', '#ed4014'],
              "1": ['开启', '#19be6b']
            }
            return h('Badge',{
              props: {
                color: statusColor[row.enabled][1],
                text:  statusColor[row.enabled][0]
              },
            });
          }
        },
        {
          title: '创建人',
          key: 'creator',
          width: 100,
        },
        {
          title: '操作人',
          key: 'operator',
          width: 100,
        },
        {
          title: '备注',
          key: 'comment',
          width: 100,
        },
        {
          title: '操 作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.updateMachine(params.index)
                  }
                }
              }, '编 辑')
            ]);
          }
        }
      ],
      machineData: [],
      selected: [],
      enabledList: [
        {
          label: '女',
          value: '0'
        },
        {
          label: '男',
          value: '1'
        }
      ]
    }
  },
  methods: {
    getAllMachines: async function(n) {
        this.searchData = {
          machine_id: '',
          enabled: -1,
          pageNo: n,
          pageSize: 100,
          totalCount: 0,
        }

        return await axios.get("/api/v1/machine", {
          params: this.searchData,
        })
    },
    dataExport: async function() {
      this.loading = true
      let exportData = []
      for (let i = 1;;i++) {
        let count = 0
        await this.getAllMachines(i).then(res => {
          exportData.push(...res.data.data)
          count = res.data.data.length
          console.log(res)
        })
        if (count === 0) {
          break
        }
      }

      this.$refs.table.exportCsv({
        filename: '导量列表',
        original: false,
        columns: this.columns.filter((col, index) => index > -1),
        data: exportData.filter((data, index) => {
          const  cron_start = data.crontab.map((col, index) => {
            return col.start
          })
          const  cron_stop = data.crontab.map((col, index) => {
            return col.stop
          })
          data.cron_start = cron_start.join("  ")
          data.cron_stop = cron_stop.join("  ")
          return data
        })
      });
      this.loading = false
    },
    getMachines() {
      const searchData = this.$_.pickBy(this.searchData, function(value, key) {
        return value !== "" && value !== 0
      });

      if (searchData.machine_id) {
        searchData.pageNo = 1
      }

      this.loading = true
      get("/api/v1/machine", searchData)
      .then((resp)=>{
        console.log(resp.data)
        this.machineData = resp.data;
        this.searchData.totalCount = resp.total;
        this.loading = false
      }).catch((e)=>{
        return e
      })
    },
    createMachine(){
      this.isEdit = false;
      this.drawer.title = "添加设备";
      this.drawer.isShow = true;
      this.resetForm()
    },
    createMachineBatch() {

    },
    deleteMachine(){
      let ids = this.selected.map(e => e.id);
      let machineIds = this.selected.map(e=> e.machine_id)
      this.$Modal.confirm({
        title: '确认删除以下ID吗？',
        content: '<p>'+ machineIds.join(",")+'</p>',
        onOk: () => {
          del("/api/v1/machine", {"id": ids})
              .then(()=>{
                this.getMachines();
                this.selected = []
                Notice.success("删除操作", "删除成功")
              }).catch((e)=>{
                return e
              })
        }
      });
    },
    updateMachine(index) {
      this.isEdit = true;
      this.drawer.title = "编辑设备";
      this.drawer.isShow = true;
      this.formData = this.$_.cloneDeep(this.machineData[index])
    },
    appendCron(){
      this.formData.crontab.push({start: "", stop: ""})
    },
    removeCron(index){
      this.formData.crontab.splice(index, 1)
    },
    selectAll(selection) {
      this.selected = selection;
    },
    selectAllCancel(selection) {
      this.selected = selection;
    },
    selecte(selection) {
      this.selected = selection;
    },
    closeDrawer() {
      this.drawer.isShow = false;
    },
    selectAllEvent ({ checked }) {
      const records = this.$refs.xTable1.getCheckboxRecords()
      console.log(checked ? '所有勾选事件' : '所有取消事件', records)
    },
    selectChangeEvent ({ checked }) {
      const records = this.$refs.xTable1.getCheckboxRecords()
      console.log(checked ? '勾选事件' : '取消事件', records)
    },
    submit() {
      this.$refs.machineForm.validate((valid) => {
        if (valid) {
          let postData = this.$_.cloneDeep(this.formData);
          this.drawer.isShow = false;
          if (this.isEdit) {
            put("/api/v1/machine", postData)
                .then(() => {
                  this.resetForm();
                  this.getMachines();
                  Notice.success("更新操作", "更新成功")
                }).catch((e) => {
                  return e
                })
          } else {
            postData.machine_id = postData.machine_id.split(/\r?\n|\r/).map(e=>e);
            post("/api/v1/machine/batch", postData)
                .then((resp) => {
                  if (resp.code === 0) {
                    this.resetForm();
                    this.getMachines();
                    Notice.success("提交操作", "添加成功")
                  } else {
                    Notice.error("提交操作", resp.msg)
                  }

                }).catch((e) => {
                  return e
                })
          }
        }
      })
    },
    resetForm() {
      this.formData = {
        machine_id: null,
        max_download: 0,
        max_upload: 0,
        recv_send_rate: 0,
        pull_ratio: 50,
        enabled: 1,
        crontab: [{start: "0 1 * * *", stop: "0 9 * * *"}],
        filter: "0.|127.|172.|192."
      }
    },
    resetSearchForm() {
      this.searchData = {
        enabled: -1
      }
    },
    formatDate (value) {
      return XEUtils.toDateString(value, 'yyyy-MM-dd HH:mm:ss.S')
    },
    //@on-page-size-change="pageOnSizeChange" @on-prev="pageOnPrev" @on-next="pageOnNext"
    pageOnChange (value) {
      this.searchData.pageNo = value
      this.getMachines()
    },
    pageOnSizeChange(value) {
      this.searchData.pageNo = 1
      this.searchData.pageSize = value
      this.getMachines()
    },
    checkValue(value) {
      // 只允许输入数字，且最小值为 1, 最大值为 100
      let val = value.replace(/[^\d]/g, '')
      const intValue = parseInt(val);
      if (isNaN(intValue)) {
        this.formData.pull_ratio = '';
      } else if (intValue === '' || !Number.isInteger(intValue) || (intValue < 1 ||
      intValue > 100)) {
        this.formData.pull_ratio = 1;
      } else {
        this.formData.pull_ratio = intValue;
      }
    }
  },
  computed: {
    isShowDelBtn() {
      return this.selected.length > 0;
    }
  },
  created() {
    this.getMachines()
  }
}
</script>

<style scoped>
/deep/ td.ivu-table-expanded-cell {
  padding: 0;
}
/deep/ .expand-row {
  margin-bottom: 0;
}
</style>